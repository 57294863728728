<template>
  <section class="hero">
    <div v-if="!isMobile()" class="columns is-gapless headers">
      <div class="column">
        <div class="header">
          <p class="title">SPA ({{spa.length}})</p>
        </div>
      </div>
      <div class="column">
        <div class="header">
          <p class="title">VOITURE ({{car.length}})</p>
        </div>
      </div>
      <div class="column">
        <div class="header">
          <p class="title">OOM</p>
        </div>
      </div>
      <div class="column">
        <div class="header">
          <p class="title">CAROUBIER</p>
        </div>
      </div>
    </div>

    <div
      class="is-gapless"
      v-bind:class="{
        columns: !isMobile(),
        'columns-m-w': isMobile(),
      }"
    >
      <div v-bind:class="{ column: !isMobile(), 'column-m': isMobile() }">
        <div v-if="isMobile()" class="mobileTitle">
          <p class="title">SPA ({{ spa.length }})</p>
        </div>
        <div class="res-cont">
          <transition-group name="fade">
            <div v-for="res of spa" :key="res.id" class="card">
              <header class="card-header">
                <div class="title-wrapper">
                  <p class="title is-6">{{ res.email }}</p>
                  <div v-if="res.status == 'pending'" class="status-w pending">
                    <p class="title status is-5">en attente de réglement</p>
                  </div>
                  <div v-else class="status-w paid">
                    <p class="title status is-5">payé</p>
                  </div>
                </div>
              </header>

              <div class="card-content">
                <p class="title detail small-margin is-5">Soins:</p>
                <p
                  v-for="service of res.selectionRaw"
                  :key="service.name"
                  class="title service is-6"
                >
                  • {{ service.name }}
                </p>
                <p class="title detail is-5">
                  Date: <span>{{ res.date }}</span>
                </p>
                <p class="title detail is-5">
                  Durée: <span>{{ res.duration }}</span>
                </p>
                <p class="title detail is-5">
                  Prix: <span>{{ res.price }} €</span>
                </p>
                <button
                  v-if="res.status == 'pending'"
                  @click="updateStatus('reservationSpa', res.id, 'paid')"
                  class="button is-success is-light"
                >
                  régler la prestation
                </button>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
      <div v-bind:class="{ column: !isMobile(), 'column-m': isMobile() }">
        <div v-if="isMobile()" class="mobileTitle">
          <p class="title">VOITURE ({{ car.length }})</p>
        </div>
        <div class="res-cont">
          <transition-group name="fade">
            <div v-for="res of car" :key="res.id" class="card">
              <header class="card-header">
                <div class="title-wrapper">
                  <p class="title is-6">{{ res.email }}</p>
                  <div v-if="res.status == 'pending'" class="status-w pending">
                    <p class="title status is-5">en attente de réglement</p>
                  </div>
                  <div v-else class="status-w paid">
                    <p class="title status is-5">payé</p>
                  </div>
                </div>
              </header>

              <div class="card-content">
                <p class="title detail is-5">
                  Model: <span>{{ res.car.name }}</span>
                </p>
                <p class="title detail is-5">
                  Date de début: <span>{{ res.start }}</span>
                </p>
                <p class="title detail is-5">
                  Date de fin: <span>{{ res.end }}</span>
                </p>
                <p class="title detail is-5">
                  Durée: <span>{{ res.duration }} Jours</span>
                </p>
                <p class="title detail is-5">
                  Prix: <span>{{ res.price }} €</span>
                </p>
                <button
                  v-if="res.status == 'pending'"
                  @click="updateStatus('locationVoiture', res.id, 'paid')"
                  class="button is-success is-light"
                >
                  régler la prestation
                </button>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
      <div v-bind:class="{ column: !isMobile(), 'column-m': isMobile() }">
        <div v-if="isMobile()" class="mobileTitle">
          <p class="title">OOM</p>
        </div>
        <div class="res-cont"></div>
      </div>
      <div v-bind:class="{ column: !isMobile(), 'column-m': isMobile() }">
        <div v-if="isMobile()" class="mobileTitle">
          <p class="title">CAROUBIER</p>
        </div>
        <div class="res-cont"></div>
      </div>
    </div>
  </section>
</template>

<script>
import {db} from "../firebase";

console.log(db);

export default {
  data() {
    return {
      spa: [],
      car: [],
    };
  },
  firestore() {
    return {
      spa: db.collection("reservationSpa").where("status", "==", "paid"),
      car: db.collection("locationVoiture").where("status", "==", "paid"),
    };
  },
  methods: {
    async updateStatus(col, doc, value) {
      await db
        .collection(col)
        .doc(doc)
        .update({ status: value })
        .then(() => {
          console.log("SUCCES");
        });
    },isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  name: "Archive",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.columns.is-gapless.headers {
  margin-bottom: 0px;
}
.header {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 99;
  width: 100%;
  height: 60px;
  padding-top: 11px;
}
.res-cont {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 100%;
  height: calc(100vh - 150px);
  overflow: scroll;
  padding: 10px;
}
.card {
  margin-bottom: 10px;
  border-radius: 30px;
}
header.card-header {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.service {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 10px !important;
  line-height: 16px;
  text-align: left;
  padding-left: 20px;
}
.title-wrapper {
  width: 100%;
  padding: 12px;
}
.status-w {
  padding: 10px;
  border-radius: 5px;
  display: block;
  width: 80%;
  margin: auto;
}
.status-w.pending {
  background-color: rgb(255, 229, 199);
}
.status-w.paid {
  background-color: rgb(226, 255, 217);
}
.pending .status {
  text-transform: uppercase;
  color: rgb(255, 94, 0);
}
.paid .status {
  text-transform: uppercase;
  color: green;
}

.card-header {
  text-align: center !important;
}
.card-header-title {
  text-align: center !important;
}

.title.detail {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 30px;
}

.detail.small-margin {
  margin-bottom: 10px;
  margin-top: 0px;
}

.detail span {
  font-weight: 600;
}
.card-footer .card-footer-item a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.25rem;
  color: green;
}
.button {
  width: 100%;
  border-radius: 17px;
  height: 50px;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 500;
}




.columns-m-w {
  display: flex;
  gap: 0px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.column-m {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  min-width: 100vw;
  height: calc(100vh - 70px);
}

@media only screen and (max-width: 768px) {
  .res-cont {
    border: none;
    height: calc(100vh - 106px);
  }
  .mobileTitle {
    height: 36px;
  }
}
</style>
